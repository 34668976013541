@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;

html {
  background: #111111;
}
.glassmorphism {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 5px 5px 40px rgba(4, 0, 0, 0.473) inset;
  backdrop-filter: blur(5.5px);
  -webkit-backdrop-filter: blur(5.5px);
  border-radius: 10px;
  border: 2px solid rgba(142, 62, 62, 0.759);
}

.text-gradient {
  font-size: 40px;
  background: -webkit-linear-gradient(right, #840000e9, rgb(207, 91, 3));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.label-text-gradient {
  background: -webkit-linear-gradient(right, #170000, rgb(255, 255, 255));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.rp-regular-gradient {
  background: -webkit-linear-gradient(right, #f60202, rgb(170, 147, 0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.rp-elite-gradient {
  background: -webkit-linear-gradient(
    right,
    rgb(170, 147, 0),
    rgb(199, 123, 1),
    #d30202
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.rp-elite-plus-gradient {
  background: -webkit-linear-gradient(
    right,
    #80ce0a,
    rgb(194, 0, 0),
    rgb(174, 5, 204)
  );
  -webkit-background-clip: text;
  /* background-clip: text; */
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 110%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: -5%;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@keyframes moveLeft {
  0% {
    opacity: 0;
    transform: translateX(-150px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.move-left {
  animation-name: moveLeft;
  animation-duration: 1s;
}

.move-right {
  animation-name: moveRight;
  animation-duration: 1s;
}

/*  buttons styling  */
.shadow-button {
  padding: auto;
  -webkit-clip-path: polygon(
    0% 15%,
    5% 15%,
    5% 0,
    95% 0,
    100% 0,
    100% 15%,
    100% 85%,
    95% 85%,
    95% 100%,
    5% 100%,
    0 100%,
    0% 85%
  );
  clip-path: polygon(
    0% 15%,
    5% 15%,
    5% 0,
    95% 0,
    100% 0,
    100% 15%,
    100% 85%,
    95% 85%,
    95% 100%,
    5% 100%,
    0 100%,
    0% 85%
  );
}

.register-button {
  -webkit-clip-path: polygon(7% 0, 100% 0, 100% 61%, 94% 100%, 0 100%, 0 40%);
  clip-path: polygon(5% 0, 100% 0, 100% 85%, 95% 100%, 0 100%, 0 15%);
  background-color: #ffa800;
}

.error-message {
  color: red;
  font-size: 0.8rem;
}
.is-ivalid {
  border-color: #f60202;
}
.form-title {
  height: 62px;

  /* font-family: "FONTSPRING DEMO - Vanguard CF Bold"; */
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 62px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}
.input-field {
  background: linear-gradient(
    90.59deg,
    rgba(56, 55, 55, 0.5) -2.17%,
    rgba(152, 152, 152, 0) 110.76%
  );
  /* Neutral/700 */
  border: 2px solid #303030;
  border-radius: 6px;
  padding: 8px;
}

.input-gradient {
  background: linear-gradient(
    90.59deg,
    rgba(56, 55, 55, 0.5) -2.17%,
    rgba(152, 152, 152, 0) 110.76%
  );
}

/* Buyer page css */
.messages {
  background: #161616;
  border: 2px solid #2e2e2e;
  border-radius: 8px;
}

.chat-heading {
  /* height: 88px;
  left: 89px;
  top: 84px; */
  background: #161616;
  border-bottom: 2px solid #2e2e2e;
  /* border-top: 0px #2e2e2e; */
}

.chat-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  /* gap: 8px; */
}

.inbox {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
  align-items: center;
  padding: 16px 9px;
  /* gap: 75px; */
  width: 100%;
  /* height: 73px; */
  background: #232222;
  border-radius: 4px;
}
/* .avatar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 40px;
  height: 41px;
  border-radius: 64px;
  background-color: #ffa800;
  clip-path: circle(50% at 50% 50%);
} */
.chat-date {
  height: 14px;
  font-family: "Public Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #9e9e9e;
  margin: 0;
  /* Inside auto layout */
  /* flex: none; */
  /* order: 1; */
  /* flex-grow: 0; */
}
.text-box {
  background: #191919;
  border: 2px solid #2e2e2e;
  border-radius: 10px;
}
.signup-box {
  background: rgba(22, 22, 22, 0.8);
  backdrop-filter: blur(2.5px);
  border: 2px solid #303030;
  /* Note: backdrop-filter has minimal browser support */
}
.react-datepicker {
  border-radius: 16px !important;
  border-color: #303030 !important;
}
.react-datepicker__triangle {
  display: none;
}
.react-datepicker__month-container {
  background-color: #141414;
  border: none;
  border-radius: 16px;
}
.react-datepicker__year-dropdown {
  background-color: #141414 !important;
}
.react-datepicker__year-option:hover {
  background-color: #ffa800 !important;
}
.react-datepicker__current-month {
  color: white !important;
}
.react-datepicker__header {
  background-color: #141414 !important;
  color: white;
  font-weight: bold;
  border-radius: 16px 16px 0 0 !important;
}
.react-datepicker__day--outside-month {
  background-color: grey !important;
  color: #141414 !important;
}
.react-datepicker__day-name {
  background-color: #141414;
  color: #ffa800 !important;
}
.react-datepicker__day {
  background-color: #141414;
  color: white !important;
}
.react-datepicker__day:hover {
  background-color: #141414 !important;
}
.react-datepicker__day--disabled {
  background-color: grey !important;
}

.img-input {
  background: linear-gradient(
    90.59deg,
    rgba(56, 55, 55, 0.5) -2.17%,
    rgba(152, 152, 152, 0) 110.76%
  );
  border: 2px dashed #303030;
  border-radius: 2px;
}
.levels-season {
  background: #1d1d1d;
  border: 1px solid #333333;
  border-radius: 6px;
}
.season-pass {
  background: linear-gradient(
    90.59deg,
    rgba(56, 55, 55, 0.5) -2.17%,
    rgba(152, 152, 152, 0) 110.76%
  );
  border: 1px solid #343434;
  border-radius: 6px;
  margin-top: 7px;
}
.social-div {
  /* background: #1f1f1f;
  border-radius: 16px; */
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}
.file-name {
  background: #303030;
  border-radius: 4px;
}
.schedule-div {
  background: #161616;
  border-radius: 12px;
}
.details-btn {
  background: rgba(247, 248, 249, 0.5);
  box-shadow: -4.4px 4.4px 4.4px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.2px);

  /* Note: backdrop-filter has minimal browser support */
}
.dropdown-div {
  background: #1f1f1f;

  /* Dark/Dark 600 */
  border: 2px solid #323232;
  border-radius: 4px;
}
.card-bg {
  /* background-image: url("https://lh3.googleusercontent.com/ogw/AOh-ky1OUzj7XQabmKamC_-Fh4NxDxvarJ9AlKU7ZbcJ=s32-c-mo")
    no-repeat; */
  background-size: cover;
  background-position-x: center;
}
.login-div {
  background: rgba(22, 22, 22, 0.8);
  /* Neutral/700 */
  border: 2px solid #303030;
  backdrop-filter: blur(2.5px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 8px;
}
.online {
  background: rgba(197, 197, 197, 0.2);
  border-radius: 104px;
}
.stepper-div {
  clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
}
.terms-button-div {
  background: rgba(247, 248, 249, 0.1);
  backdrop-filter: blur(5px);
}
.scrollbar::-webkit-scrollbar {
  display: none;
}
html::-webkit-scrollbar {
  display: none;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}
.notification-box {
  background: #1a1a1a;
  box-shadow: inset -1px -1px 1px rgba(106, 106, 106, 0.2);
  border-radius: 4px;
}
.navbar > a {
  display: block;
  padding: 8px;
  border-bottom: #111111 2px solid;
}
.navbar > a:hover {
  background-color: #ffa800;
  color: white;
}
.nav > a:hover {
  transform: scale(1.05);
  color: #ffa800;
}
button:hover {
  transform: scale(1.05);
  transition: transform 0.2s;
}
.background-image {
  background-image: url(images/background.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.home-bg-img {
  background-image: url(images/HomeBg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.overflow-hidden {
  overflow: hidden;
}
@tailwind utilities;
